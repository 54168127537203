import { Easing, interpolate } from 'remotion';
import { AnimationBase, getAnimationCycle } from './AnimationBase';

export class Bounce extends AnimationBase {
  getName(): string {
    return 'Bounce';
  }

  getCssBaseName(): string {
    return 'bounce';
  }

  getCss(frame: number, speedFactor: number): string {
    const { animationCycle, frameInCycle } = getAnimationCycle({ frame, speed: 2 * speedFactor });

    const translateValue = interpolate(
      frameInCycle,
      [0, animationCycle * 0.1, animationCycle],
      [0, -30, 0],
      {
        easing: Easing.bounce
      }
    );

    const scaleValue = interpolate(
      frameInCycle,
      [0, animationCycle * 0.1, animationCycle],
      [1.0, 1.1, 1.0],
      {
        easing: Easing.bounce
      }
    );

    const cssName = this.getCssName(speedFactor);

    const css = `.${cssName} {
      transform-origin: center bottom;
      transform-box: fill-box;
      transform: translateY(${translateValue}px) scaleY(${scaleValue});
      -webkit-transform-origin: center bottom;
      -webkit-transform-box: fill-box;
      -webkit-transform: translateY(${translateValue}px) scaleY(${scaleValue});
      -ms-transform-origin: center bottom;
      -ms-transform-box: fill-box;
      -ms-transform: translateY(${translateValue}px) scaleY(${scaleValue});
    }`.replace(' ', '');

    return css;
  }
}
