import React from 'react';

import './FigmaPluginButton.css';

const FIGMA_PLUGIN_PAGE_URL = 'https://figma.com/community/plugin/1186534312086647967';

export const FigmaPluginButton = () => {
  return (
    <a href={FIGMA_PLUGIN_PAGE_URL} target="_blank" rel="noreferrer">
      <button className="figma-plugin-button">Get The Figma Plugin</button>
    </a>
  );
};
