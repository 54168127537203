import { interpolate } from 'remotion';
import { AnimationBase, getAnimationCycle } from './AnimationBase';

export class FadeIn extends AnimationBase {
  getName(): string {
    return 'Fade In';
  }

  getCssBaseName(): string {
    return 'fade-in';
  }

  getCss(frame: number, speedFactor: number): string {
    const { animationCycle, frameInCycle } = getAnimationCycle({ frame, speed: speedFactor });

    // 0.0 -> 1.0 -> 0.0
    // 0%     50%    100%
    const opacityValue = interpolate(
      frameInCycle,
      [0, animationCycle * 0.5, animationCycle],
      [0.0, 1.0, 0.0]
    );
    const cssName = this.getCssName(speedFactor);

    const css = `.${cssName} {
      opacity: ${opacityValue};
    }`.replace(' ', '');

    return css;
  }
}
