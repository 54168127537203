import { interpolate } from 'remotion';
import { AnimationBase, getAnimationCycle } from './AnimationBase';

export class Nod extends AnimationBase {
  getName(): string {
    return 'Nod';
  }

  getCssBaseName(): string {
    return 'nod';
  }

  getCss(frame: number, speedFactor: number): string {
    const { animationCycle, frameInCycle } = getAnimationCycle({ frame, speed: 6 * speedFactor });

    // 0 -> 16 -> 0 -> -16 -> 0
    // 0%  25%   50%    75%   100%
    const translateValue = interpolate(
      frameInCycle,
      [0, animationCycle * 0.25, animationCycle * 0.5, animationCycle * 0.75, animationCycle],
      [0, 16, 0, -16, 0]
    );
    const cssName = this.getCssName(speedFactor);

    const css = `.${cssName} {
        transform-origin: center;
        transform-box: fill-box;
        transform: translateY(${translateValue}px);
        -webkit-transform-origin: center;
        -webkit-transform-box: fill-box;
        -webkit-transform: translateY(${translateValue}px);
        -ms-transform-origin: center;
        -ms-transform-box: fill-box;
        -ms-transform: translateY(${translateValue}px);
      }`.replace(' ', '');

    return css;
  }
}
