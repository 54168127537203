import { v4 as uuidv4 } from 'uuid';
import { API } from 'aws-amplify';
import { AnimationWithSpeed } from './AnimatedSvg';
import { NoAnimation } from './animations/None';

const API_NAME = 'fidgetApi';
const RENDER_JOB_API_PATH = '/renderjob';

export interface SendRenderJobData {
  email: string;
  jobId: string;
  svgKey: string;
  overallProgress: number;
  elementAnimations: { [key: string]: AnimationWithSpeed };
  svgWidth: number;
  svgHeight: number;
  reschedules: number;
}

interface StartRenderJobParams {
  email: string;
  svgKey: string;
  elementAnimations: Map<string, AnimationWithSpeed>;
  svgWidth: number;
  svgHeight: number;
}

export interface RenderJobData {
  renderId: string;
}

export async function startRenderJob({
  email,
  svgKey,
  elementAnimations,
  svgWidth,
  svgHeight
}: StartRenderJobParams): Promise<RenderJobData> {
  const noAnimationName = new NoAnimation().getName();

  const elementAniationsWithoutNone = Object.fromEntries(
    [...elementAnimations].filter(([_, v]) => v.animation != noAnimationName)
  );

  const data: { body: SendRenderJobData } = {
    body: {
      email,
      jobId: uuidv4(),
      svgKey,
      overallProgress: 0,
      elementAnimations: elementAniationsWithoutNone,
      svgWidth,
      svgHeight,
      reschedules: 0
    }
  };

  return API.post(API_NAME, RENDER_JOB_API_PATH, data);
}
