import { DURATION_IN_FRAMES } from '../constants';

export abstract class AnimationBase {
  abstract getName(): string;
  abstract getCssBaseName(): string;

  abstract getCss(frame: number, speedFactor: number): string;

  getCssName(speedFactor: number): string {
    const speedFactorString = speedFactor.toString().replace('.', 'point');
    return `${this.getCssBaseName()}-${speedFactorString}`;
  }
}

type AnimationCylcleParams = {
  frame: number;
  speed: number;
};

export const getAnimationCycle = ({
  frame,
  speed
}: AnimationCylcleParams): { animationCycle: number; frameInCycle: number } => {
  const animationCycle = DURATION_IN_FRAMES / 4 / speed;
  const frameInCycle = frame % animationCycle;
  return { animationCycle, frameInCycle };
};
