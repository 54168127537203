import React from 'react';

import './LoadingSpinner.css';

import loadingSpinner from './assets/Loader.svg';

type LoadingSpinnerProps = {
  size: number;
};

export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { size } = props;
  return (
    <img
      src={loadingSpinner}
      alt="loading spinner"
      className="LoadingSpinner"
      style={{ opacity: '1.0', width: `${size}px` }}
    />
  );
};
