import React from 'react';
import { useEventCallback } from '../util';
import { LoadingSpinner } from '../LoadingSpinner';

import './DownloadButton.css';

export type StatusMessage = {
  type: 'Loading' | 'Info' | 'Success' | 'Error';
  message: string;
};

type DownloadButtonProps = {
  message?: StatusMessage;
  disabled: boolean;
  onClick: () => void;
};

export const DownloadButton = (props: DownloadButtonProps) => {
  const onClickCallback = useEventCallback(props.onClick);

  const getClassName = () => {
    if (props.message?.type === 'Success') return 'DownloadButton extended success';
    if (props.message?.type === 'Error') return 'DownloadButton extended error';
    if (props.message) return 'DownloadButton extended';
    return 'DownloadButton';
  };

  const getContent = () => {
    if (!props.message) return 'Get Download Link';
    if (!props.message.message) return <LoadingSpinner size={64} />;
    return props.message.message;
  };

  return (
    <button
      onClick={onClickCallback}
      disabled={props.disabled}
      className={getClassName()}
      aria-label="download button"
    >
      {getContent()}
    </button>
  );
};
