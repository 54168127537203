import { useEffect, useRef, useState } from 'react';

import Bowser from 'bowser';

// Saves incoming handler to the ref in order to avoid "useCallback hell"
export function useEventCallback<T>(handler?: (value: T) => void): (value: T) => void {
  // as seen here: https://github.com/omgovich/react-colorful/blob/a85e5b36b55cae7e95c73c8ecde0bc881e8e3b1f/src/hooks/useEventCallback.ts
  const callbackRef = useRef(handler);
  const fn = useRef((value: T) => {
    callbackRef.current && callbackRef.current(value);
  });
  callbackRef.current = handler;

  return fn.current;
}

export function useDelayUnmount(isMounted: boolean, delayTime: number) {
  const [showDiv, setShowDiv] = useState(false);
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined = undefined;
    if (isMounted && !showDiv) {
      setShowDiv(true);
    } else if (!isMounted && showDiv) {
      timeoutId = setTimeout(() => setShowDiv(false), delayTime);
    }
    if (timeoutId) {
      return () => clearTimeout(timeoutId);
    }
  }, [isMounted, delayTime, showDiv]);
  return showDiv;
}

export function isSupportedBrowser(): boolean {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserName() !== 'Safari';
}

export function encodeValue(val: string): string {
  return val.replace('<', '%3C').replace('>', '%3E');
}

export function decodeValue(val: string): string {
  return val.replace('%3C', '<').replace('%3E', '>');
}
