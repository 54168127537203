import React, { cloneElement, useState } from 'react';
import {
  offset,
  flip,
  shift,
  autoUpdate,
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  useClick,
  FloatingFocusManager
} from '@floating-ui/react';

interface Props {
  render: (data: { close: () => void }) => React.ReactNode;
  children: JSX.Element;
}

export const Popover = ({ children, render }: Props) => {
  const [open, setOpen] = useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift()],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context)
  ]);

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref: refs.setReference, ...children.props }))}
      {open && (
        <FloatingFocusManager
          context={context}
          modal={false}
          order={['reference', 'content']}
          returnFocus={false}
        >
          <div
            ref={refs.setFloating}
            className="Popover"
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              zIndex: 10
            }}
            {...getFloatingProps()}
          >
            {render({
              close: () => {
                setOpen(false);
              }
            })}
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
