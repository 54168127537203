import React, { useCallback, useEffect, useState } from 'react';
import { Img, AbsoluteFill, useCurrentFrame, delayRender, continueRender } from 'remotion';
import {
  setStyleInSvg,
  svgToBase64,
  Element,
  base64ToSvg,
  svgObjectFromKey,
  setClassesOnElement
} from './SvgObject';
import { ANIMATIONS, ANIMATIONS_BY_NAME } from './animations/Animations';
import uniqBy from 'lodash.uniqby';

export const SELECTED_ELEMENT_CLASS = 'selected-element';

export type AnimationWithSpeed = {
  animation: string;
  speedFactor: number;
};

export interface Props {
  svg: Element;
  usedAnimations: AnimationWithSpeed[];
}

export const AnimatedSvg = ({ svg, usedAnimations }: Props): JSX.Element => {
  const frame = useCurrentFrame();

  const usedAnimationsUniq = uniqBy(
    usedAnimations,
    (usedAnimation) => `${usedAnimation.animation}${usedAnimation.speedFactor}`
  );

  const animationsCss = usedAnimationsUniq
    .map((usedAnimation) =>
      ANIMATIONS_BY_NAME.get(usedAnimation.animation)?.getCss(frame, usedAnimation.speedFactor)
    )
    .join(' ');

  const selectedCss = `.${SELECTED_ELEMENT_CLASS} {
    outline: 2px dashed grey;
  }`.replace(' ', '');

  setStyleInSvg(svg, `${selectedCss} ${animationsCss}`);

  return (
    <AbsoluteFill>
      <Img src={`data:image/svg+xml;base64,${svgToBase64(svg)}`} alt="Animated SVG image" />
    </AbsoluteFill>
  );
};

type AnimatedSvgFromBase64XmlProps = {
  svgEncoded: string;
  usedAnimations: AnimationWithSpeed[];
};

export const AnimatedSvgFromBase64Xml = ({
  svgEncoded,
  usedAnimations
}: AnimatedSvgFromBase64XmlProps): JSX.Element => {
  const svgElement = base64ToSvg(svgEncoded);

  return <AnimatedSvg svg={svgElement} usedAnimations={usedAnimations} />;
};

type AnimatedSvgFromKeyAndAnimationsProps = {
  svgKey: string;
  elementAnimations: { [key: string]: AnimationWithSpeed };
};

export const AnimatedSvgFromKeyAndAnimations = ({
  svgKey,
  elementAnimations
}: AnimatedSvgFromKeyAndAnimationsProps): JSX.Element | null => {
  const [baseSvg, setBaseSvg] = useState<Element | null>(null);
  const [handle] = useState(() => delayRender());

  const fetchData = useCallback(async () => {
    const svg = await svgObjectFromKey(svgKey);
    setBaseSvg(svg);

    continueRender(handle);
  }, [handle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (baseSvg) {
    let svg = baseSvg;

    // TODO: maybe a reduce?
    Object.entries(elementAnimations).forEach(([elementId, animationForElement]) => {
      const animationObject = ANIMATIONS.find(
        (animation) => animation.getName() === animationForElement.animation
      );

      if (animationObject) {
        const animationClassName = animationObject?.getCssName(animationForElement.speedFactor);
        svg = setClassesOnElement(svg, elementId, [animationClassName]);
      } else {
        console.error(`no animation object found for ${animationForElement}`);
      }
    });

    const usedAnimations = new Array(...Object.values(elementAnimations));

    return <AnimatedSvg svg={svg} usedAnimations={usedAnimations} />;
  } else {
    return null;
  }
};
