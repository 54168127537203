import React, { ReactElement, useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import {
  useFloating,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal
} from '@floating-ui/react';

import fidgetLogo from './assets/fidget_logo.svg';

import './DownloadDialog.css';

const API_NAME = 'fidgetApi';
const RENDER_PROGRESS_API_PATH = '/render-progress/{renderId}';

export interface DownloadDialogProps {
  renderId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function DownloadDialog(props: DownloadDialogProps): ReactElement | null {
  const { renderId, isOpen, setIsOpen } = props;

  const [hasError, setHasError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState<string | undefined>(undefined);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen
  });

  const click = useClick(context);
  const role = useRole(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });

  const { getFloatingProps } = useInteractions([click, role, dismiss]);

  const getRenderProgress = async () => {
    API.get(API_NAME, RENDER_PROGRESS_API_PATH.replace('{renderId}', renderId), {}).then(
      (response: RenderProgressResponse) => {
        setHasError(response.hasError);
        setProgress(response.progress);
        setIsDone(response.isDone);
        setPresignedUrl(response.presignedUrl);

        if (!response.isDone && !response.hasError) {
          setTimeout(getRenderProgress, 5000);
        }
      }
    );
  };

  useEffect(() => {
    getRenderProgress();
  }, [renderId]);

  const downloadVideo = async () => {
    if (presignedUrl == null) {
      return;
    }
    window.location.href = presignedUrl;
  };

  return (
    <FloatingPortal>
      {isOpen && (
        <FloatingOverlay className="Dialog-overlay" lockScroll>
          <FloatingFocusManager context={context}>
            <div className="DialogBody" ref={refs.setFloating} {...getFloatingProps()}>
              <img src={fidgetLogo} className="DialogBody__logo" alt="Fidget Logo" />

              {hasError && (
                <div className="DialogBody__title">There was an error rendering your video</div>
              )}

              {isDone && !hasError && <div className="DialogBody__title">Your video is ready!</div>}

              {!isDone && !hasError && (
                <React.Fragment>
                  <div className="DialogBody__subheader">your download is processing...</div>
                  <div className="DialogBody__title">This could take a few minutes...</div>
                  <progress className="DialogBody__progress" max="1" value={progress} />
                </React.Fragment>
              )}

              <button
                disabled={presignedUrl == null}
                onClick={downloadVideo}
                className="DialogBody__button"
              >
                Download
              </button>
              <button onClick={() => setIsOpen(false)} className="DialogBody__cancel">
                Cancel
              </button>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  );
}

interface RenderProgressResponse {
  hasError: boolean;
  progress: number;
  isDone: boolean;
  presignedUrl?: string;
}
