import React from 'react';

import fidgetLogo from './assets/fidget_logo.svg';

import './UnsupportedBrowser.css';

export const UnsupportedBrowserPage = () => {
  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <div className="unsupported-browser-page-container">
      <img src={fidgetLogo} className="fidget-logo" alt="Fidget Logo" />
      <span className="unsupported-browser-title">Fidget Works Best On Chrome</span>
      <span className="unsupported-browser-content">
        Please copy the link to your Fidget below and paste it in Chrome. We’ve automatically logged
        your attempt to use Fidget in another browser and are working to add support in the near
        future.
      </span>
      <button className="copy-page-link-button" onClick={copyUrlToClipboard}>
        Copy Page Link
      </button>
    </div>
  );
};
