import { AnimationBase } from './AnimationBase';

export class NoAnimation extends AnimationBase {
  getName(): string {
    return 'None';
  }

  getCssBaseName(): string {
    return 'no-animation';
  }

  getCss(_frame: number, _speedFactor: number): string {
    return '';
  }
}
