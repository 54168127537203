import { Easing, interpolate } from 'remotion';
import { AnimationBase, getAnimationCycle } from './AnimationBase';

export class Grow extends AnimationBase {
  getName(): string {
    return 'Grow';
  }

  getCssBaseName(): string {
    return 'grow';
  }

  getCss(frame: number, speedFactor: number): string {
    const { animationCycle, frameInCycle } = getAnimationCycle({ frame, speed: speedFactor });

    const scaleValue = interpolate(
      frameInCycle,
      [0, animationCycle * 0.5, animationCycle],
      [1.0, 1.2, 1.0],
      {
        easing: Easing.inOut(Easing.sin)
      }
    );
    const cssName = this.getCssName(speedFactor);

    const css = `.${cssName} {
      transform-origin: center;
      transform-box: fill-box;
      transform: scale(${scaleValue});
      -webkit-transform-origin: center;
      -webkit-transform-box: fill-box;
      -webkit-transform: scale(${scaleValue});
      -ms-transform-origin: center;
      -ms-transform-box: fill-box;
      -ms-transform: scale(${scaleValue});
    }`.replace(' ', '');

    return css;
  }
}
