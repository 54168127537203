import { interpolate } from 'remotion';
import { AnimationBase, getAnimationCycle } from './AnimationBase';

export class Spin extends AnimationBase {
  getName(): string {
    return 'Spin';
  }

  getCssBaseName(): string {
    return 'spin';
  }

  getCss(frame: number, speedFactor: number): string {
    const { animationCycle, frameInCycle } = getAnimationCycle({ frame, speed: speedFactor });

    const rotationValue = interpolate(frameInCycle, [0, animationCycle], [0, 360]);
    const cssName = this.getCssName(speedFactor);

    const css = `.${cssName} {
      transform-origin: center;
      transform-box: fill-box;
      transform: rotate(${rotationValue}deg);
      -webkit-transform-origin: center;
      -webkit-transform-box: fill-box;
      -webkit-transform: rotate(${rotationValue}deg);
      -ms-transform-origin: center;
      -ms-transform-box: fill-box;
      -ms-transform: rotate(${rotationValue}deg);
    }`.replace(' ', '');

    return css;
  }
}
