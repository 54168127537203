import React from 'react';
import { ANIMATIONS } from './animations/Animations';
import { useEventCallback } from './util';
import { AnimationWithSpeed } from './AnimatedSvg';

import './AnimationSelector.css';

const SPEED_FACTORS = [
  {
    factor: 0.25,
    text: '.25x'
  },
  {
    factor: 0.5,
    text: '.5x'
  },
  {
    factor: 1,
    text: '1x'
  },
  {
    factor: 1.5,
    text: '1.5x'
  },
  {
    factor: 2,
    text: '2x'
  }
];

type Props = {
  selectedAnimation: AnimationWithSpeed;
  onAnimationChange: (selectedAnimation: AnimationWithSpeed) => void;
};

export const AnimationSelector = ({ selectedAnimation, onAnimationChange }: Props) => {
  const onAnimationChangeCallback = useEventCallback(onAnimationChange);

  const renderAnimationSelection = (animation: string) => {
    const isSelected = animation === selectedAnimation?.animation;

    // TODO: use default ..selectedAnimatons in onAnimationChangeCallback
    return (
      <div
        className={
          'animation-type-option ' +
          (isSelected ? 'animation-option-selected' : 'animation-option-not-selected')
        }
        onClick={() =>
          onAnimationChangeCallback({
            animation,
            speedFactor: selectedAnimation?.speedFactor
          })
        }
        key={`animation-option-${animation}`}
      >
        {animation}
      </div>
    );
  };

  const renderSpeedFactor = (factor: number, text: string) => {
    const isSelected = factor === selectedAnimation?.speedFactor;

    // TODO: use default ..selectedAnimatons in onAnimationChangeCallback
    return (
      <div
        className={
          'animation-speed-option ' +
          (isSelected ? 'animation-option-selected' : 'animation-option-not-selected')
        }
        onClick={() =>
          onAnimationChangeCallback({
            speedFactor: factor,
            animation: selectedAnimation?.animation
          })
        }
        key={`speed-option-${factor}`}
      >
        {text}
      </div>
    );
  };

  return (
    <div className="animation-selector-container">
      <div className="speed-selector">
        <div className="selector-header">SPEED</div>
        <div className="speed-selector-options">
          {SPEED_FACTORS.map((speedFactor) =>
            renderSpeedFactor(speedFactor.factor, speedFactor.text)
          )}
        </div>
      </div>

      <div className="animation-selector">
        <div className="selector-header">CHOOSE AN ANIMATION</div>
        <div className="animation-selector-options">
          {ANIMATIONS.map((animationObject) => renderAnimationSelection(animationObject.getName()))}
        </div>
      </div>
    </div>
  );
};
