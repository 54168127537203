import { AnimationBase } from './AnimationBase';
import { Bounce } from './Bounce';
import { FadeIn } from './FadeIn';
import { Grow } from './Grow';
import { Jitter } from './Jitter';
import { Nod } from './Nod';
import { NoAnimation } from './None';
import { Shake } from './Shake';
import { Shrink } from './Shrink';
import { Spin } from './Spin';
import { Tada } from './Tada';

export const ANIMATIONS: AnimationBase[] = [
  new NoAnimation(),
  new Spin(),
  new Jitter(),
  new Nod(),
  new Grow(),
  new Shrink(),
  new FadeIn(),
  new Bounce(),
  new Shake(),
  new Tada()
];

/* eslint-disable */
export const ANIMATIONS_BY_NAME: Map<string, AnimationBase> = new Map(
  ANIMATIONS.map((animation) => [animation.getName(), animation])
);
