import React from 'react';
import { Player } from '@remotion/player';
import { AnimatedSvg, Props as AnimatedSvgProps } from './AnimatedSvg';
import { DURATION_IN_FRAMES, FPS } from './constants';

export type Props = {
  compositionWidth: number;
  compositionHeight: number;
  animatedSvgProps: AnimatedSvgProps;
};

export const Preview = ({ compositionWidth, compositionHeight, animatedSvgProps }: Props) => {
  return (
    <Player
      component={AnimatedSvg}
      compositionWidth={compositionWidth}
      compositionHeight={compositionHeight}
      controls
      allowFullscreen
      showVolumeControls={false}
      spaceKeyToPlayOrPause={false}
      autoPlay={process.env.NODE_ENV === 'production'}
      loop
      durationInFrames={DURATION_IN_FRAMES}
      fps={FPS}
      inputProps={animatedSvgProps}
      style={{
        width: '100%'
      }}
    />
  );
};
