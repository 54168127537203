import { xml2js, js2xml, Element } from 'xml-js';
import { Buffer } from 'buffer';
import { encodeValue } from './util';

export { Element } from 'xml-js';

const SVG_UPLOADS_URL = 'https://svg-uploads.s3.us-west-1.amazonaws.com/';

export const xmlToSvgObject = (svgXmlString: string): Element => {
  return xml2js(svgXmlString, { compact: false, attributeValueFn: encodeValue }) as Element;
};

export const svgObjectToXml = (svgElement: Element): string => {
  return js2xml(svgElement);
};

const getSvgTag = (svg: Element): Element => {
  if (svg.elements === undefined) {
    throw new Error('SVG element has no elements.');
  }

  if (svg.elements.length !== 1) {
    throw new Error('Expecting one element in svgXmlString.elements');
  }

  const svgElement: Element = svg.elements[0];
  if (svgElement.name !== 'svg') {
    throw new Error("Expecting first element's name in svgXmlString to be 'svg'");
  }

  return svgElement;
};

const getTopLevelGroup = (svg: Element): Element => {
  const svgElement = getSvgTag(svg);

  const groupElement =
    svgElement.elements && svgElement.elements.find((element) => element.name === 'g');

  if (groupElement === undefined) {
    console.warn('No <g> tag found on top level. Using svg element as top level group.');
    return svgElement;
  }

  return groupElement;
};

export const setStyleInSvg = (svg: Element, css: string) => {
  const svgElement = getSvgTag(svg);

  if (svgElement.elements === undefined) {
    throw new Error('<svg> has no elements.');
  }

  const styleElement: Element = {
    type: 'element',
    name: 'style',
    elements: [
      {
        type: 'text',
        text: css
      }
    ]
  };

  svgElement.elements = svgElement.elements.filter((element) => element.name !== 'style');
  svgElement.elements.push(styleElement);
};

export const getElementIds = (svg: Element): string[] => {
  const group = getTopLevelGroup(svg);

  if (group.elements === undefined) {
    return [];
  }

  const ids = group.elements
    .map((element) => element.attributes?.id?.toString())
    .filter((element): element is string => !!element);

  return ids;
};

const findElement = (svg: Element, elementId: string): Element | undefined => {
  const group = getTopLevelGroup(svg);

  if (group.elements === undefined) {
    throw new Error('Group has no elements.');
  }

  return group.elements.find(
    (element) =>
      element.attributes &&
      Object.values(element.attributes).filter((value) => value === elementId).length > 0
  );
};

export const setClassesOnElement = (
  svg: Element,
  elementId: string,
  classes: string[]
): Element => {
  const element = findElement(svg, elementId);
  if (element === undefined) {
    console.log(`Can't find element with id '${elementId}'`);
    return svg;
  }

  const classString = classes.join(' ');

  if (element.attributes) {
    element.attributes['class'] = classString;
  } else {
    element.attributes = { class: classString };
  }

  return svg;
};

export const addClassesToElement = (
  svg: Element,
  elementId: string,
  classes: string[]
): Element => {
  const element = findElement(svg, elementId);
  if (element === undefined) {
    console.log(`Can't find element with id '${elementId}'`);
    return svg;
  }

  const updatedClasses = new Set<string>();
  const existingClasses = element.attributes?.class;
  existingClasses &&
    existingClasses
      .toString()
      .split(' ')
      .forEach((c) => updatedClasses.add(c));
  classes.forEach((c) => updatedClasses.add(c));

  const classString = Array.from(updatedClasses.values()).join(' ');

  if (element.attributes) {
    element.attributes['class'] = classString;
  } else {
    element.attributes = { class: classString };
  }

  return svg;
};

export const removeClassesFromElement = (
  svg: Element,
  elementId: string,
  classes: string[]
): Element => {
  const element = findElement(svg, elementId);
  if (element === undefined) {
    console.log(`Can't find element with id '${elementId}'`);
    return svg;
  }

  const updatedClasses = new Set<string>();
  const existingClasses = element.attributes?.class;
  existingClasses &&
    existingClasses
      .toString()
      .split(' ')
      .forEach((c) => updatedClasses.add(c));
  classes.forEach((c) => updatedClasses.delete(c));

  const classString = Array.from(updatedClasses.values()).join(' ');

  if (element.attributes) {
    element.attributes['class'] = classString;
  } else {
    element.attributes = { class: classString };
  }

  return svg;
};

export const svgToBase64 = (svgElement: Element): string => {
  const svgXmlString = svgObjectToXml(svgElement).replace('&', '');

  return Buffer.from(svgXmlString, 'utf8').toString('base64');
};

export const base64ToSvg = (encodedSvg: string): Element => {
  const xmlString = Buffer.from(encodedSvg, 'base64').toString('utf8');
  return xmlToSvgObject(xmlString);
};

export const getSvgDimensions = (svg: Element): { height: number; width: number } => {
  const svgElement = getSvgTag(svg);

  return {
    height: Number(svgElement.attributes?.height),
    width: Number(svgElement.attributes?.width)
  };
};

export const svgObjectFromKey = async (svgKey: string): Promise<Element> => {
  const fileUrl = `${SVG_UPLOADS_URL}${svgKey}`;

  return fetch(fileUrl, {
    mode: 'cors'
  })
    .then((response) => response.text())
    .then((data) => xmlToSvgObject(data));
};
