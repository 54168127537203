import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import {
  setClassesOnElement,
  addClassesToElement,
  removeClassesFromElement,
  getElementIds,
  Element,
  getSvgDimensions,
  svgObjectFromKey
} from './SvgObject';
import { ElementSelector } from './ElementSelector';
import { AnimationSelector } from './AnimationSelector';
import { ANIMATIONS } from './animations/Animations';
import { Preview } from './Preview';
import { NoAnimation } from './animations/None';
import { Spin } from './animations/Spin';
import { AnimationWithSpeed, SELECTED_ELEMENT_CLASS } from './AnimatedSvg';
import { EMAIL_REGEX } from './constants';
import { startRenderJob } from './RenderJob';
import { DownloadButton, StatusMessage } from './DownloadButton/DownloadButton';
import DownloadDialog from './DownloadDialog';
import { FigmaPluginButton } from './FigmaPluginButton';
import { Popover } from './Popover';
import { isSupportedBrowser } from './util';
import { UnsupportedBrowserPage } from './UnsupportedBrowser';

import fidgetLogo from './assets/fidget_logo.svg';
import downloadButtonSymbol from './assets/DownloadButton.svg';

import './App.css';

Amplify.configure(awsconfig);

const DEFAULT_SVG_KEY = 'c24ba2fe-1c7f-4b4c-8c04-c787d8c44192';

export const App: React.FC = () => {
  const [svg, setSvg] = useState<Element | null>(null);
  const [svgKey, setSvgKey] = useState('');
  const [elements, setElements] = useState<string[]>([]);

  const [svgWidth, setSvgWidth] = useState<number | null>(null);
  const [svgHeight, setSvgHeight] = useState<number | null>(null);

  const [selectedElement, setSelectedElement] = useState<string | null>(null);
  const [elementAnimations, setElementAnimations] = useState(new Map<string, AnimationWithSpeed>());

  const [email, setEmail] = useState('');
  const [downloadMessage, setDownloadMessage] = useState<StatusMessage | undefined>(undefined);
  const [renderButtonDisabled, setRenderButtonDisabled] = useState(false);

  const [renderId, setRenderId] = useState<string | null>(null); // ziza1sx20k
  const [downloadDialogIsOpen, setDownloadDialogIsOpen] = useState(false);

  const [showDefaultSvg, setShowDefaultSvg] = useState(true);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isUserUpload = queryParams.has('upload');

    setShowDefaultSvg(!isUserUpload);

    const uploadKey = (queryParams.get('upload') || DEFAULT_SVG_KEY) + '.svg';
    setSvgKey(uploadKey);

    svgObjectFromKey(uploadKey).then((svgElement) => setSvg(svgElement));

    const renderIdParam = queryParams.get('renderId');
    if (renderIdParam) {
      setRenderId(renderIdParam);
    }
  }, []);

  useEffect(() => {
    if (svg) {
      const elementIds = getElementIds(svg);
      setElements(elementIds);
      elementIds && setSelectedElement(elementIds[0]);

      const { height, width } = getSvgDimensions(svg);
      const evenHeight = height % 2 === 0 ? height : height - 1;
      const evenWidth = width % 2 === 0 ? width : width - 1;
      setSvgHeight(evenHeight);
      setSvgWidth(evenWidth);

      const noAnimationObj = new NoAnimation();
      const newElementAnimations = elementIds.reduce((acc, elementId) => {
        return acc.set(elementId, { animation: noAnimationObj.getName(), speedFactor: 1 });
      }, new Map<string, AnimationWithSpeed>());

      if (showDefaultSvg) {
        const fidgetElement = 'Fidget';
        setSelectedElement(fidgetElement);
        const spinAnimation = new Spin();
        const fidgetAnimationObj = { animation: spinAnimation.getName(), speedFactor: 1 };
        newElementAnimations.set(fidgetElement, fidgetAnimationObj);

        const updatedSvg = setClassesOnElement(svg, fidgetElement, [
          spinAnimation.getCssName(fidgetAnimationObj.speedFactor),
          SELECTED_ELEMENT_CLASS
        ]);
        setSvg(updatedSvg);
      }

      setElementAnimations(newElementAnimations);
      setRenderButtonDisabled(false);
      setDownloadMessage(undefined);
    }
  }, [svg]);

  useEffect(() => {
    if (svg && selectedElement) {
      elements.forEach((element: string) =>
        removeClassesFromElement(svg, element, ['selected-element'])
      );
      const updatedSvg = addClassesToElement(svg, selectedElement, [SELECTED_ELEMENT_CLASS]);
      setSvg(updatedSvg);
    }
  }, [selectedElement]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('renderId') !== renderId) {
      renderId != null ? queryParams.set('renderId', renderId) : queryParams.delete('renderId');
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams}`);
    }
    if (renderId != null) {
      setDownloadDialogIsOpen(true);
    }
  }, [renderId]);

  const handleAnimationChange = (selectedAnimation: AnimationWithSpeed) => {
    if (selectedElement === null) {
      console.log('handleAnimationChange called but no element is selected');
    }

    selectedElement &&
      setElementAnimations(new Map(elementAnimations.set(selectedElement, selectedAnimation)));

    if (svg && selectedElement) {
      const animationObject = ANIMATIONS.find(
        (animation) => animation.getName() === selectedAnimation.animation
      );

      if (animationObject !== undefined) {
        const updatedSvg = setClassesOnElement(svg, selectedElement, [
          animationObject.getCssName(selectedAnimation.speedFactor),
          SELECTED_ELEMENT_CLASS
        ]);
        setSvg(updatedSvg);
      } else {
        console.log(`no animation object found for ${selectedAnimation}`);
      }
    }

    setRenderButtonDisabled(false);
    setDownloadMessage(undefined);
    setRenderId(null);
  };

  const downloadButtonDisabled = () => {
    return renderButtonDisabled || !email.match(EMAIL_REGEX);
  };

  const requestDownloadLink = async () => {
    if (svg && svgWidth && svgHeight) {
      setRenderButtonDisabled(false);
      setDownloadMessage({ type: 'Loading', message: '' });

      let svgToRender = svg;
      elements.forEach(
        (element: string) =>
          (svgToRender = removeClassesFromElement(svgToRender, element, ['selected-element']))
      );

      startRenderJob({
        email,
        svgKey,
        elementAnimations,
        svgHeight,
        svgWidth
      })
        .then((data) => {
          setRenderId(data.renderId);
          setDownloadMessage({
            type: 'Success',
            message:
              'your video is rendering and will be sent to the email above within a few minutes.'
          });
        })
        .catch((error) => {
          console.error('Error starting render job:', error);
          setRenderId(null);
          setDownloadMessage({ type: 'Error', message: 'something went wrong.' });
        });
    }
  };

  const renderDownloadDialog = (_data: { close: () => void }) => {
    if (renderId != null) {
      setDownloadDialogIsOpen(true);
      return null;
    }

    return (
      <div className="download-dialog-container">
        <input
          type="email"
          className="emailInput"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email@example.com"
          value={email}
          aria-label="download email"
        />
        <DownloadButton
          message={downloadMessage}
          onClick={requestDownloadLink}
          disabled={downloadButtonDisabled()}
        />
      </div>
    );
  };

  return (
    <div className="fidget-app">
      <div className="header">
        <div className="header-content">
          <div className="logo-container">
            <img src={fidgetLogo} className="logo-image" alt="Fidget Logo" />
            <span>fidget</span>
            <span>.video</span>
          </div>
          {showDefaultSvg && <FigmaPluginButton />}
          {!showDefaultSvg && (
            <Popover render={renderDownloadDialog}>
              <img
                src={downloadButtonSymbol}
                className="download-button-symbol"
                alt="Download Button Logo"
              />
            </Popover>
          )}
        </div>
      </div>

      {isSupportedBrowser() ? (
        <div className="body-container">
          <div className="element-selector-container">
            <ElementSelector
              elements={elements}
              selectedElement={selectedElement}
              onElementSelection={setSelectedElement}
            />
          </div>
          <div className="preview-container">
            {svg && svgWidth && svgHeight && (
              <Preview
                compositionWidth={svgWidth}
                compositionHeight={svgHeight}
                animatedSvgProps={{
                  svg,
                  usedAnimations: Array.from(elementAnimations.values())
                }}
              />
            )}
          </div>

          <AnimationSelector
            selectedAnimation={
              (selectedElement && elementAnimations.get(selectedElement)) || {
                animation: NoAnimation.name,
                speedFactor: 1
              }
            }
            onAnimationChange={handleAnimationChange}
          />

          {renderId && (
            <DownloadDialog
              renderId={renderId}
              isOpen={downloadDialogIsOpen}
              setIsOpen={setDownloadDialogIsOpen}
            />
          )}
        </div>
      ) : (
        <UnsupportedBrowserPage />
      )}
    </div>
  );
};

export default App;
