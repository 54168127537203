import React from 'react';
import { decodeValue, useEventCallback } from './util';

import './ElementSelector.css';

type Props = {
  elements: string[];
  selectedElement: string | null;
  onElementSelection: (selectedElement: string) => void;
};

export const ElementSelector = ({ elements, selectedElement, onElementSelection }: Props) => {
  const onElementSelectionCallback = useEventCallback(onElementSelection);

  const renderElement = (element: string, index: number) => {
    const className = element === selectedElement ? 'selected-element' : 'selection-element';
    return (
      <div
        className={className}
        onClick={() => {
          onElementSelectionCallback(element);
        }}
        key={`selection-element-${index}`}
      >
        {decodeValue(element)}
      </div>
    );
  };

  return <div className="element-selector">{elements.map(renderElement)}</div>;
};
